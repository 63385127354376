import request from '@/services/request'
import apis from '@/services/apis'

export const getOrders = (pageSize, pageNumber, query) => {
  let filter = ''
  if (query) {
    filter = `&filter=${query}`
  }
  return request.get({
    url: `${apis.orders}?pageSize=${pageSize}&pageNumber=${pageNumber}${filter}`
  })
}

export const getOrderById = (id) => {
  return request.get({
    url: `${apis.orders}/${id}`
  })
}

export const linkUnlinkOrder = (orderId, upstreamOrderId, isLink) => {
  const data = {
    orderId: orderId,
    upstreamOrderId: upstreamOrderId,
    isLink: isLink
  }
  return request.post({
    url: apis.linkUnlinkOrder,
    data: data
  })
}

export const createOrder = (order) => {
  return request.post({
    url: apis.orders,
    data: order
  })
}

export const updateOrder = (order) => {
  /*
    return request.put({
      url: apis.orders,
      data: order
    })
  */
    return new Promise((resolve, reject) => {
    request.put({
      url: apis.orders,
      data: order
    }).then(res => {
      resolve(res)
    }).catch(e => {
      reject(e) })
  })

}

export const updateOrderProductFinalQty = (orderId, data) => {
  return request.post({
    url: `${apis.orders}/${orderId}/modify_product_final_quantity`,
    data: data
  })
}

export const deleteOrder = (orderId) => {
  return request.delete(`${apis.orders}/${orderId}`)
}

export const confirmOrder = (orderId, data) => {
  return request.post({
    url: `${apis.orders}/${orderId}/confirm`,
    data: data
  })
}

export const cancelOrder = (orderId) => {
  return request.post({
    url: `${apis.orders}/${orderId}/cancel`
  })
}

export const verifyOrder = (data) => {
  return request.post({
    url: apis.verifyOrder,
    data: data
  })
}

export const verifyOrderCA = (order) => {
  return request.post({
    url: apis.verifyOrderCA,
    data: order
  })
}

export const updateOrderComm = (orderId, data) => {
  return request.post({
    url: `${apis.orders}/${orderId}/commissions`,
    data: data
  })
}

export const generateOrderPdf = (orderId) => {
  return request.getFile({
    url: `${apis.orders}/${orderId}/generatePdf`
  })
}

export const isOrderCancellable = (orderId) => {
  return request.post({
    url: `${apis.orders}/${orderId}/is_order_cancellable`
  })
}
